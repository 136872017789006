import { useNavigate } from "react-router-dom";
import home from "src/assets/images/home.png";
import { FiChevronRight, FiRefreshCcw } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import logo from "src/assets/images/logo-white.png";
import logoDismore from "src/assets/images/dismore-white.svg";
import cafetariaAirlangga from "src/assets/images/cafetaria-airlangga.png";
import iconMoney from "src/assets/icon/money.png";
import { CartContext } from "src/context/CartContext";
import { REACT_APP_VERSION } from "src/configs/Version";

export function HomePage() {
  const navigate = useNavigate();

  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    setCart([]);
  }, []);

  return (
    <div className="h-full relative overflow-hidden flex flex-col justify-center">
      <div className="absolute h-full w-full z-0">
        <img src={home} alt="" className="w-full h-full" />
      </div>

      <div className="h-3/4 w-full relative text-white flex flex-col justify-center">
        <div className="flex gap-8 items-center justify-center">
          <img src={logo} className="w-[250px]" />
          <img src={logoDismore} className="h-56" />
        </div>

        <div className="mb-[150px] bg-opacity-50 w-full px-32 py-7 mt-10">
          <h2 className="font-semibold text-[80px]">
            Welcome to <br></br>
            <span className="font-black text-[120px] leading-[120px] my-4">
              Cafetaria Unair Aseec
            </span>
            {/* <img src={cafetariaAirlangga} className="w-[750px] mt-3" /> */}
          </h2>
          <h4 className="text-[50px] font-semibold italic mt-4">Let's Enjoy</h4>
          <p className="text-[36px] font-semibold mt-2">
            v {REACT_APP_VERSION}
          </p>
        </div>
      </div>

      <div className="relative w-full grow">
        <div className="grid grid-cols-12">
          <div className="col-span-5 flex flex-col items-center">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="rounded-full bg-main p-10 text-center mb-5 shadow-xl text-white"
            >
              <FiRefreshCcw size={55} />
            </button>
            <h4 className="text-[28px] text-center text-white font-medium">
              Refresh <br /> Application
            </h4>
          </div>
          <div className="col-span-7 flex flex-col items-end">
            <button
              onClick={() => {
                navigate("/type-service");
              }}
              className=" py-8 pl-14 pr-5 w-full mb-10 text-[44px] rounded-l-full bg-main text-white font-bold flex justify-between items-center"
            >
              Touch to Order
              <div>
                <FiChevronRight />
              </div>
            </button>

            <a
              href=""
              className=" py-8 pl-14 pr-5 text-[36px] italic underline"
            >
              Call for assistance
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
